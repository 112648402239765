<template>
  <div ref="student" class="student">
    <keep-alive>
      <list-template
          :current-page="page"
          :isCheckInputBox="false"
          :loading="loading"
          :search-config="searchConfig"
          :table-config="tableConfig"
          :table-data="tableData"
          :total="total"
          @onChange="onChange"
          @onChangePage="changeCurrentPage"
          @onHandle="tableHandle"
          @onReset="search=null;searchConfig[3].options = []"
          @onSearch="onSearch"
      ></list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List"
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      // 表格搜索配置
      searchConfig: [
        {
          prop: "teacher_name",
          placeholder: "搜索教师姓名"
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          value: 'id',
          label: 'school_name',
          change: (e,search) => {
            setTimeout(() => {
              this.search = search
              if (search.class_id) this.search.class_id = ''
              if (search.grade_id) this.getClass({grade_id: search.grade_id, school_id: e})
              this.$forceUpdate()
            })
          },
          options: []
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          value: 'id',
          label: 'name',
          change: (e, search) => {
            setTimeout(() => {
              this.search = search
              if (search.class_id) this.search.class_id = ''
              if (search.school_id) this.getClass({grade_id: e, school_id: search.school_id})
              this.$forceUpdate()
            })
          },
          options: []
        },
        {
          prop: 'class_id',
          placeholder: '筛选班级',
          tag: 'select',
          value: 'class_id',
          label: 'class_name',
          options: []
        }
      ],
      // 表格配置
      tableConfig: [
        {prop: "teacher", label: "班主任姓名"},
        {prop: "class", label: "行政班"},
        {prop: "garde", label: "年级"},
        {prop: "school", label: "校区"},
        {prop: "created_at", label: "提交日期"},
        {
          prop:"",
          label:"操作",
          width:140,
          handle:true,
          showOverflowTooltip: false,
          buttons: [{ type:"view", text:"查看" }]
        },
      ],
      search: {},
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData:[],
      loading:true,
    }
  },
  computed: {
    ...mapState(["page"])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData();
    this.getSchool()
    this.getGrade()
  },
  activated() {

  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData();
    },
    // 获取数据
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get("/api/teacher/class-week-report/lists", {params: {...search, page}}).then(res => {
        let {data} = res.data;
        this.tableData = data.list;
        this.total = data.page.total;
        this.loading = false;
      }).catch(err => this.loading = false)
    },

    getSchool() {
      this.$_axios.get("/site/school").then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            id: "0",
            isSelected: false,
            school_name: "全部"
          })
          this.searchConfig[1].options = data;
        }
      })
    },

    getGrade() {
      this.$_axios.get("/site/grades").then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            id: "0",
            isSelected: false,
            name: "全部"
          })
          this.searchConfig[2].options = data;
        }else{
          this.searchConfig[2].options = []
        }
      })
    },

    getClass(params) {
      console.log(123)
      this.$_axios.get("/site/role-admin-class", {params}).then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            class_id: "0",
            class_name: "全部"
          })
          this.searchConfig[3].options = data;
        }else{
          this.searchConfig[3].options = []
        }
      })
    },

    onChange(val) {
      this.search = val;
    },

    // 搜索功能
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val;
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row) {
      this.$router.push("./details?id=" + row.id);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
